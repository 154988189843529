import { observable, action } from 'mobx';
import * as Parse from 'parse';
import { Pointer } from 'helpers/Parse';
import { EditActivityDataInterface } from 'components/Forms/ActivityForm';
import LoadPromise from 'helpers/LoadPromise';
import _ from 'lodash';
import { replaceObjectById } from 'helpers/CollectionHelper';

export class ActivityDetailsStore {

    @observable activity: Parse.Object | null = null;
    @observable participants: Array<Parse.Object> = [];

    @action
    resetStore() {
        this.activity = null;
        this.participants = [];
    }

    @action
    getParticipants(activityId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                var gamePlayer = Parse.Object.extend('GamePlayer');
                var gamePlayerQuery = new Parse.Query(gamePlayer);
                gamePlayerQuery.equalTo('game', Pointer('Game', activityId));
                gamePlayerQuery.include('user');

                const participants = await gamePlayerQuery.find();
                if (participants) {
                    const orderedParticipants = _.orderBy(participants, gp => gp.get('user').get('name'));
                    this.participants = orderedParticipants;
                    resolve(orderedParticipants);
                }
                reject();
            }
            catch (error) {
                reject(error.message);
            }
        });
    }

    @action
    getActivity(activityId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                var activityQuery = new Parse.Query('Game');

                activityQuery.include('group');
                activityQuery.include('venue');
                activityQuery.include('host');
                activityQuery.include('recurringGame');

                activityQuery.equalTo("objectId", activityId);

                const activity = await activityQuery.first();
                if (activity) {
                    this.activity = activity;
                    resolve(activity);
                }
                reject();
            }
            catch (error) {
                reject(error.message);
            }
        });
    }

    @action
    editActivity(data: EditActivityDataInterface) {
        return LoadPromise(async (resolve, reject) => {

            try {
                const activity = await Parse.Cloud.run('requestEditGame', data);
                this.activity = activity;
                resolve(activity);
            }
            catch (error) {
                reject(error.message);
            }

        });
    }

    @action
    cancelActivity(activityId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const res = await Parse.Cloud.run('gameRequestCancel', { gameId: activityId });
                resolve(res);
            }
            catch (error) {
                reject(error.message);
            }
        });
    }

    @action
    acceptActivityRequest(gamePlayer: Parse.Object) {
        return LoadPromise(async (resolve, reject) => {
            try {
                await Parse.Cloud.run('gamePlayerRequestAccept', { gamePlayerId: gamePlayer.id });
                gamePlayer.set('playerStatus', 'ATPlayerStatusAccepted');
                const players = replaceObjectById(this.participants, gamePlayer);
                this.participants = players;
                resolve();
            }
            catch (error) {
                reject(error.message);
            }
        })
    }
    @action
    deleteActivityRequest(gamePlayer: Parse.Object) {
        return LoadPromise(async (resolve, reject) => {
            try {
                await Parse.Cloud.run('gamePlayerRequestDecline', { gamePlayerId: gamePlayer.id });
                gamePlayer.set('playerStatus', 'ATPlayerStatusDeclined');
                const players = replaceObjectById(this.participants, gamePlayer);
                this.participants = players;
                resolve();
            }
            catch (error) {
                reject(error.message);
            }
        })
    }


}

export const activityDetailsStore = new ActivityDetailsStore();