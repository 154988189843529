import { observable, action } from 'mobx';
import { Parse, Pointer } from 'helpers/Parse';
import { GroupMemberStatusEnum } from './FitnessStore';
import LoadPromise from 'helpers/LoadPromise';

export class HomeStore {

    @observable totalActivities: number = 0;
    @observable activeMembers: number = 0;
    @observable totalParticipants: number = 0;
    @observable pendingMemberships: number = 0;

    @action
    resetStore() {
        this.totalActivities = 0;
        this.activeMembers = 0;
        this.totalParticipants = 0;
        this.pendingMemberships = 0;
    }

    @action
    getStats(customerId: string) {
        this.getTotalActivities(customerId);
        this.getTotalParticipants(customerId);
        // this.getActiveMembers(customerId);
    }

    @action
    getTotalActivities(customerId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const activityQuery = new Parse.Query('Game');
                activityQuery.notContainedIn('gameStatus', ['ATGameStatusCancelled']);
                // activityQuery.equalTo('customerAffiliation', Pointer('Customer', customerId));
                const groupQuery = new Parse.Query('Group');
                groupQuery.equalTo('customerAffiliation', Pointer('Customer', customerId));
                
                activityQuery.matchesQuery('group', groupQuery);
                const count = await activityQuery.count();
                this.totalActivities = count;

                resolve(count);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    getTotalParticipants(customerId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const gamePlayerQuery = new Parse.Query('GamePlayer');
                gamePlayerQuery.containedIn('playerStatus', ['ATPlayerStatusHosting', 'ATPlayerStatusAccepted']);
                // gamePlayerQuery.equalTo('customerAffiliation', Pointer('Customer', customerId));
                const groupQuery = new Parse.Query('Group');
                groupQuery.equalTo('customerAffiliation', Pointer('Customer', customerId));
                
                const gameQuery = new Parse.Query('Game');
                gameQuery.matchesQuery('group', groupQuery);
                
                gamePlayerQuery.matchesQuery('game', gameQuery);
                
                const count = await gamePlayerQuery.count();

                this.totalParticipants = count;
                resolve(count);

            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    getActiveMembers(customerId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const count = await this._getMembers(customerId, [GroupMemberStatusEnum.ADMIN, GroupMemberStatusEnum.MEMBER], true);
                this.activeMembers = count as number;
                resolve(count);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    getPendingMembers(customerId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const count = await this._getMembers(customerId, [GroupMemberStatusEnum.PENDING]);
                this.pendingMemberships = count as number;
                resolve(count);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    _getMembers(customerId: string, status: Array<string>, distinctUsers: boolean = false) {
        return new Promise(async (resolve, reject) => {
            try {
                const groupQuery = new Parse.Query('Group');
                groupQuery.equalTo('customerAffiliation', Pointer('Customer', customerId));
                const groups = await groupQuery.find();

                const groupMemberQuery = new Parse.Query('GroupMember');
                groupMemberQuery.containedIn('memberStatus', status);
                groupMemberQuery.include('user');
                groupMemberQuery.containedIn('group', groups);

                // if (distinctUsers) {
                //     groupMemberQuery.distinct('user')
                // }

                const count = await groupMemberQuery.count();
                resolve(count);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }
}

export const homeStore = new HomeStore();